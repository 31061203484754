define('m03/controllers/contact', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Controller.extend({

    debug: true,
    message: "",
    sname: "",
    email: "",
    testemail: "",

    sendingMail: false,

    nachrichtVerfassen: true,

    actions: {

      sendMessage: function sendMessage() {

        var self = this;

        if (self.get('testemail') === "") {

          console.log('sendMessage()');

          self.set('sendingMail', true);

          var messageToSend = self.get('message');
          var nameOfSender = self.get('sname');
          var email = self.get('email');

          var application = self.controllerFor('application');

          var bauteile = self.controllerFor('bauteile');

          var applicationdata = application.get('model').application.objectAt(0);
          var applicationdataJSON = JSON.stringify(applicationdata);

          var projektdatendata = application.get('model').projektdaten.objectAt(0);
          var projektdatendataJSON = JSON.stringify(projektdatendata);
          //
          // var geometriedata = application.get('model').geometrie.objectAt(0);
          // var geometriedataJSON = JSON.stringify(geometriedata);
          //
          // var bauteiledata = application.get('model').bauteile.objectAt(0);
          // var bauteiledataJSON = JSON.stringify(bauteiledata);
          //
          // var zwverdata = application.get('model').zwischenschichtverstaerkung.objectAt(0);
          // var zwverdataJSON = JSON.stringify(zwverdata);
          //
          // var lasteinwirkungdata = application.get('model').lasteinwirkung.objectAt(0);
          // var lasteinwirkungdataJSON = JSON.stringify(lasteinwirkungdata);
          //
          // var uebergabedaten = $.extend(true, JSON.parse(projektdatendataJSON), JSON.parse(geometriedataJSON), JSON.parse(bauteiledataJSON), JSON.parse(zwverdataJSON), JSON.parse(lasteinwirkungdataJSON));
          //
          //     var shearconnection = {
          //       "ConnectionTyp": geometriedata.get('ConnectionType'),
          //       "von1zu2": true,
          //       "ZwischenSchichtVorh": geometriedata.get('ZwischenSchichtVorh'),
          //       "VerstaerkungVorh": geometriedata.get('VerstaerkungVorh'),
          //       "ShearPlaneMultiplicator": geometriedata.get('ShearPlaneMultiplicator'),
          //       "Setztiefe": Number(geometriedata.get('Setztiefe')).toFixed(1),
          //       "ShowMessageOnError": false,
          //       "StarreZwischenSchicht": zwverdata.get('StarreZwischenSchicht')
          //     };
          //
          //
          //     if (self.get('stahl')) {
          //       var timberelement1 = {
          //         "Querschnittswerte": {
          //           "t_Fastener": bauteiledata.get('TimberElement2_b'),
          //           "b": bauteiledata.get('TimberElement2_b'),
          //           "h": bauteiledata.get('TimberElement2_h')
          //         },
          //         "ALFA_RAD_LoadGrain": "0.0",
          //         "Predrilled": geometriedata.get('Predrilled')
          //       };
          //     } else {
          //       var timberelement1 = {
          //         "Querschnittswerte": {
          //           "QuerschnittsID": "1",
          //           "t_Fastener": Number(bauteiledata.get('TimberElement1_b')).toFixed(1),
          //           "b": Number(bauteiledata.get('TimberElement1_b')).toFixed(1),
          //           "h": Number(bauteiledata.get('TimberElement1_h')).toFixed(1)
          //         },
          //         "ALFA_RAD_LoadGrain": "0.0",
          //         "Predrilled": geometriedata.get('Predrilled')
          //       };
          //     }
          //
          //     var timberelement2 = {
          //       "Querschnittswerte": {
          //         "QuerschnittsID": "1",
          //         "t_Fastener": Number(bauteiledata.get('TimberElement2_b')).toFixed(1),
          //         "b": Number(bauteiledata.get('TimberElement2_b')).toFixed(1),
          //         "h": Number(bauteiledata.get('TimberElement2_h')).toFixed(1)
          //       },
          //       "ALFA_RAD_LoadGrain": "0.0",
          //       "Predrilled": geometriedata.get('Predrilled')
          //     };
          //
          //     if (self.get('zsver') == 0) {
          //       var interlayerelement = {
          //         "Querschnittswerte": {
          //           "QuerschnittsID": "1",
          //           "t_Fastener": 0.0,
          //           "b": 0.0,
          //           "h": 0.0
          //         },
          //         "ALFA_RAD_LoadGrain": 0.0,
          //         "Predrilled": false
          //       };
          //     } else if (self.get('zsver') == 1) {
          //       var interlayerelement = {
          //         "Querschnittswerte": {
          //           "QuerschnittsID": "1",
          //           "t_Fastener": zwverdata.get('dickeBlass'),
          //           "b": zwverdata.get('dickeBlass'),
          //           "h": 0.0
          //         },
          //         "ALFA_RAD_LoadGrain": 0.0,
          //         "Predrilled": false
          //       };
          //     } else if (self.get('zsver') == 2) {
          //       var interlayerelement = {
          //         "Querschnittswerte": {
          //           "QuerschnittsID": "1",
          //           "t_Fastener": zwverdata.get('dickeWerner'),
          //           "b": zwverdata.get('dickeWerner'),
          //           "h": 0.0
          //         },
          //         "ALFA_RAD_LoadGrain": 0.0,
          //         "Predrilled": false
          //       };
          //     }
          //
          //     var steelplate = {
          //       // "Bezeichnung": "S235",
          //       "Bezeichnung": self.getStahlname(Number(bauteile.get('htfkl'))),
          //       "f_yk": Number(bauteile.get('htfkl')).toFixed(1),
          //       "Querschnittswerte": {
          //         "t_Fastener": Number(bauteiledata.get('TimberElement1_b')).toFixed(1),
          //         "b": Number(bauteiledata.get('TimberElement1_b')).toFixed(1),
          //         "h": Number(bauteiledata.get('TimberElement1_h')).toFixed(1)
          //       }
          //     };
          //
          //     uebergabedaten.ShearConnection = shearconnection;
          //     uebergabedaten.TimberElement1 = timberelement1;
          //     uebergabedaten.TimberElement2 = timberelement2;
          //     uebergabedaten.InterlayerElement = interlayerelement;
          //     uebergabedaten.SteelPlate = steelplate;
          //
          // var JSONdata = JSON.stringify(uebergabedaten);
          var JSONdata = JSON.stringify(self.controllerFor('supercontroller').uebergabedatenErzeugen());

          var server = application.get('server');
          var pfad = application.get('pfad');

          var cid = applicationdata.get('Calculation_ID');

          if (self.debug) {
            console.log('cid: ' + cid);
            console.log('server: ' + server);
            console.log('pfad: ' + pfad);
            console.log('nameOfSender: ' + nameOfSender);
            console.log('messageToSend: ');
            console.log(messageToSend);
            console.log('kennung: ');
            console.log(applicationdataJSON);
            console.log('paras:');
            console.log(JSONdata);
          }

          var suffix = "hi03";
          var subject = 'M03 HTML CLIENT - Kontaktformular / Anfrage';
          if (application.get('itw') === true) {
            subject = 'M03 ITW HTML CLIENT - Kontaktformular / Anfrage';
            suffix = "it03";
          } else if (application.get('swg') === true) {
            subject = 'M03 SWG HTML CLIENT - Kontaktformular / Anfrage';
            suffix = "sw03";
          }

          $.ajax({
            type: "POST",
            url: server + "sendeEmail/",
            data: {
              kennung: applicationdataJSON,
              paras: JSONdata,
              suffix: suffix,
              cid: cid,
              body: messageToSend,
              name: nameOfSender,
              subj: subject,
              sender: email,
              receiver: "support@swg-engineering.de"
            }
          }).done(function (data) {
            self.set('sendingMail', false);
            self.set('nachrichtVerfassen', false);
            console.log(data);
            // datei öffnen, bzw. link anzeigen
          });
        }
      },

      neueNachrichtVerfassen: function neueNachrichtVerfassen() {
        var self = this;
        self.set('message', "");
        this.transitionToRoute('projektdaten');
        self.set('nachrichtVerfassen', true);
      }

    },

    getStahlname: function getStahlname(index) {
      var self = this;
      var pklassenDB = fklDB.fkl_stahl;

      var tmp = "";
      var len = pklassenDB.length;

      for (var i = 0; i < len; i++) {
        if (pklassenDB[i]["fklind"] === index) {
          tmp = pklassenDB[i]["fkl"];
        }
      }
      return tmp;
    }

  });

});