define('m03/templates/optionen', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        isHTMLBars: true,
        revision: "Ember@1.12.0",
        blockParams: 0,
        cachedFragment: null,
        hasRendered: false,
        build: function build(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","helptext");
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2,"src","assets/images/info.png");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          dom.setAttribute(el2,"class","helptextfenster");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        render: function render(context, env, contextualElement) {
          var dom = env.dom;
          var hooks = env.hooks, element = hooks.element, get = hooks.get, subexpr = hooks.subexpr, concat = hooks.concat, attribute = hooks.attribute, content = hooks.content;
          dom.detectNamespace(contextualElement);
          var fragment;
          if (env.useFragmentCache && dom.canClone) {
            if (this.cachedFragment === null) {
              fragment = this.build(dom);
              if (this.hasRendered) {
                this.cachedFragment = fragment;
              } else {
                this.hasRendered = true;
              }
            }
            if (this.cachedFragment) {
              fragment = dom.cloneNode(this.cachedFragment, true);
            }
          } else {
            fragment = this.build(dom);
          }
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(element0, [1]);
          var attrMorph0 = dom.createAttrMorph(element1, 'class');
          var morph0 = dom.createUnsafeMorphAt(dom.childAt(element0, [3]),0,0);
          element(env, element0, context, "action", ["removeClass", "vm_um_1d_versetzenHilfetextFocused"], {"on": "mouseEnter"});
          attribute(env, attrMorph0, element1, "class", concat(env, ["infobutton ", subexpr(env, context, "if", [get(env, context, "vm_um_1d_versetzenHilfetextFocused"), "infobutton-shine"], {})]));
          content(env, morph0, context, "vm_um_1d_versetzenHilfetext");
          return fragment;
        }
      };
    }());
    return {
      isHTMLBars: true,
      revision: "Ember@1.12.0",
      blockParams: 0,
      cachedFragment: null,
      hasRendered: false,
      build: function build(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"id","subcontainer");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","form-horizontal");
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","headline-container last-headline");
        var el4 = dom.createElement("h3");
        dom.setAttribute(el4,"class","formheadline");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","arrow-container");
        var el5 = dom.createTextNode(" ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5,"class","arrow");
        dom.setAttribute(el5,"data-toggle","collapse");
        dom.setAttribute(el5,"data-target","#Verbindungsmittelversatz");
        var el6 = dom.createTextNode(" ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"id","Verbindungsmittelversatz");
        dom.setAttribute(el3,"class","collapse in input-area");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4,"class","form-group");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","col-sm-5 singleCombobox");
        var el6 = dom.createTextNode("\n            ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode(" ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","col-sm-1");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5,"class","col-sm-1");
        var el6 = dom.createTextNode("\n");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment(" <h3 class=\"formheadline\" data-toggle=\"collapse\" data-target=\"#Nachweise\">{{t 'nachweise'}}<span class=\"arrow\"> </span></h3>\n\n    <div id=\"Nachweise\" class=\"collapse in input-area\">\n\n      <div class=\"form-group\">\n        <div class=\"col-sm-5 singleCombobox\">\n            {{input type=\"checkbox\" name=\"querschnittsnachweise\" checked=querschnittsnachweise}} {{t 'querschnittsnachweiseFuehren'}}\n        </div>\n\n        <div class=\"col-sm-1\"></div>\n\n        <div class=\"col-sm-1\">\n{{#if display_querschnittsnachweiseFuehrenHilfetext}}\n            <div class=\"helptext\" {{action \"removeClass\" \"querschnittsnachweiseFuehrenHilfetextFocused\" on=\"mouseEnter\" }}>\n              <img class=\"infobutton {{if querschnittsnachweiseFuehrenHilfetextFocused 'infobutton-shine'}}\" src=\"assets/images/info.png\" />\n              <span class=\"helptextfenster\">{{{querschnittsnachweiseFuehrenHilfetext}}}</span>\n            </div>\n          {{/if}}        </div>\n      </div>\n\n{{#if querschnittsnachweise}}\n        <div class=\"form-group\">\n          <div class=\"col-sm-5 singleCombobox\">\n              {{input type=\"checkbox\" name=\"aussermitteBeruecksichtigen\" checked=aussermitteBeruecksichtigen}} {{t 'aussermittigeLasteinleitungBeruecksichtigen'}}\n          </div>\n\n          <div class=\"col-sm-1\"></div>\n\n          <div class=\"col-sm-1\">\n            {{#if display_aussermitteBeruecksichtigenHilfetext}}\n              <div class=\"helptext\" {{action \"removeClass\" \"aussermitteBeruecksichtigenHilfetextFocused\" on=\"mouseEnter\" }}>\n                <img class=\"infobutton {{if aussermitteBeruecksichtigenHilfetextFocused 'infobutton-shine'}}\" src=\"assets/images/info.png\" />\n                <span class=\"helptextfenster\">{{{aussermitteBeruecksichtigenHilfetext}}}</span>\n              </div>\n            {{/if}}\n          </div>\n        </div>\n      {{/if}}    </div> ");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      render: function render(context, env, contextualElement) {
        var dom = env.dom;
        var hooks = env.hooks, inline = hooks.inline, get = hooks.get, block = hooks.block;
        dom.detectNamespace(contextualElement);
        var fragment;
        if (env.useFragmentCache && dom.canClone) {
          if (this.cachedFragment === null) {
            fragment = this.build(dom);
            if (this.hasRendered) {
              this.cachedFragment = fragment;
            } else {
              this.hasRendered = true;
            }
          }
          if (this.cachedFragment) {
            fragment = dom.cloneNode(this.cachedFragment, true);
          }
        } else {
          fragment = this.build(dom);
        }
        var element2 = dom.childAt(fragment, [0, 1]);
        var element3 = dom.childAt(element2, [3, 1]);
        var element4 = dom.childAt(element3, [1]);
        var morph0 = dom.createMorphAt(dom.childAt(element2, [1, 0]),0,0);
        var morph1 = dom.createMorphAt(element4,1,1);
        var morph2 = dom.createMorphAt(element4,3,3);
        var morph3 = dom.createMorphAt(dom.childAt(element3, [5]),1,1);
        inline(env, morph0, context, "t", ["verbindungsmittel"], {});
        inline(env, morph1, context, "input", [], {"type": "checkbox", "name": "verbindungsmittelVersetzt", "checked": get(env, context, "verbindungsmittelVersetzt")});
        inline(env, morph2, context, "t", ["vm_um_1d_versetzen"], {});
        block(env, morph3, context, "if", [get(env, context, "display_vm_um_1d_versetzenHilfetext")], {}, child0, null);
        return fragment;
      }
    };
  }()));

});