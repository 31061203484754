define('m03/components/schraub-e', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var Schraube = Ember['default'].Component.extend({

    tagName: 'transform',
    layoutName: 'schraub-e',
    attributeBindings: ['translation', 'rotation', 'bboxcenter', 'bboxsize', 'center', 'scale', 'scaleorientation'],
    classNames: ['schraubenKlasse'],
    bboxcenter: "0 0 0",
    bboxsize: "-1,-1,-1",
    scale: "1,1,1",
    scaleorientation: "0,0,0,0",

    center: (function () {
      var values = '0 0 0';
      // var name = this.get('name');

      return values;
    }).property(''),

    translation: (function () {
      var values = '0 0 0';

      var x3d = this.get('model').objectAt(0);
      var name = this.get('name');
      var bauteile = x3d.get('bauteile');

      var x = 0,
          y = 0,
          z = 0;

      if (x3d.get('ergebnisGeladen')) {
        x = bauteile.findBy('id', name).get('punkt').objectAt(0).get('x') / 10;
        y = bauteile.findBy('id', name).get('punkt').objectAt(0).get('y') / 10;
        z = bauteile.findBy('id', name).get('punkt').objectAt(0).get('z') / 10;
      } else {
        x = bauteile.findBy('id', name).get('translations').objectAt(0).get('x') / 10;
        y = bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('y') / 20;
        z = bauteile.findBy('id', name).get('translations').objectAt(0).get('z') / 10;
      }

      values = x + ' ' + y + ' ' + z;

      return values;
    }).property('model.firstObject.istHt', 'model.firstObject.schraubenGekreuzt'),

    // Rotation in der XY-Ebene, um die z-Achse
    rotation: (function () {
      var self = this;
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');

      var values = '';
      var drehwinckelYAchse = 0,
          drehwinckelZAchse = 0;

      if (x3d.get('ergebnisGeladen')) {

        var x = bauteile.findBy('id', name).get('richtungsvektor').objectAt(0).get('x');
        var y = bauteile.findBy('id', name).get('richtungsvektor').objectAt(0).get('y');
        var z = bauteile.findBy('id', name).get('richtungsvektor').objectAt(0).get('z');

        drehwinckelYAchse = self.getWinkel({ x: x, y: 0, z: z }, { x: 0, y: 0, z: 1 });
        drehwinckelZAchse = self.getWinkel({ x: x, y: y, z: z }, { x: 0, y: 1, z: 0 });
      } else {
        drehwinckelZAchse = Number(this.get('model').objectAt(0).get('bauteile').findBy('id', name).get('rotations').objectAt(0).get('z'));
      }

      var xWertRotationsachse = Math.cos(drehwinckelYAchse);
      var zWertRotationsachse = -Math.sin(drehwinckelYAchse);

      values = xWertRotationsachse + ' 0 ' + zWertRotationsachse + ' ' + drehwinckelZAchse;

      return values;
    }).property('model.firstObject.vWinkel', 'model.firstObject.hWinkel', 'model.firstObject.schraubenGekreuzt'),

    getWinkel: function getWinkel(vektor, bezugsVektor) {
      var self = this;

      var xx = vektor.x * bezugsVektor.x;
      var yy = vektor.y * bezugsVektor.y;
      var zz = vektor.z * bezugsVektor.z;

      var l_vektor = self.vektorLaenge(vektor);
      var l_bezugsVektor = self.vektorLaenge(bezugsVektor);

      var winkel = Math.acos((xx + yy + zz) / (l_vektor * l_bezugsVektor));

      return winkel;
    },

    vektorLaenge: function vektorLaenge(v) {
      return Math.sqrt(Math.pow(v.x, 2) + Math.pow(v.y, 2) + Math.pow(v.z, 2));
    },

    uscheibeAnzeigen: (function () {
      var x3d = this.get('model').objectAt(0);

      return x3d.get("uScheibe") && !x3d.get('ergebnisGeladen');
    }).property('model.firstObject.uScheibe'),

    farbe: (function () {
      // var name = this.get('name');
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var item = bauteile.findBy('id', name);

      var vmTypID = Number(item.get('screwProperties').objectAt(0).get('vmTypID'));

      var values = item.get('diffusecolors').objectAt(0).get('rot') + " " + item.get('diffusecolors').objectAt(0).get('gruen') + " " + item.get('diffusecolors').objectAt(0).get('blau');
      // var values = "0.51 0.745 0.98";

      if (vmTypID === 0) {
        //stabduebel
        values = "0.51 0.745 0.98";
      }

      return values;
    }).property('')

  });

  exports['default'] = Schraube;

});